var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"accent-2 box",class:[
		_vm.moved ? 'moved' : ''
	],attrs:{"color":_vm.color,"ripple":false,"elevation":"24","to":{
		name: 'test',
		params: {
			testid: _vm.test.id
		}
	}}},[(_vm.test.result)?_c('v-card-title',{staticClass:"font-weight-black font-italic black--text"},[_vm._v(" "+_vm._s(_vm.test.result.result)+"% ")]):_vm._e(),_c('v-card-text',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.test.name))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }