<template>
	<transition-group
		tag="div"
		class="grid">
		<div v-for="item in items"
			:key="item.id" class="box"
			:class="{moved: moved === item.id}"
			@click="() => r(item.id)">

			<slot :item="item"/>

		</div>
	</transition-group>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			required: true,
		},
	},
	data: () => ({
		moved: null,
	}),
	methods: {
		r(id) {
			if (this.moved === null) {
				this.moved = id
			} else {
				this.moved = null
			}
		},
	},
}
</script>

<style>

.box {
  width: 100%;
  height: 100%;
  max-width: 8em;
  max-height: 8em;
  transition: all .6s cubic-bezier(0.77, 0, 0.175, 1);
}

.box:hover {
/*  Some tricky fix for z-index  */
  z-index: 2;
}

.box.moved {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  z-index: 2;
}

.box-move {
}
</style>