<template>
	<v-container>
		<h1>{{ subject.name }}</h1>
		<small>Тести</small>

		<v-data-iterator
			:items="filteredTests"
			:search="search"
			:sort-by="sortBy">

			<template v-slot:header>
				<v-toolbar
					class="mb-1"
					elevation="0"
					color="transparent">
					<v-text-field
						v-model="search"
						clearable
						hide-details
						prepend-inner-icon="mdi-magnify"
						label="Пошук"/>
					
					<v-spacer/>

					<v-select
						v-model="filterBy"
						hide-details
						:items="filterKeys"
						label="Фільтрувати"/>

					<v-select
						v-model="sortBy"
						hide-details
						:items="sortKeys"
						label="Сортувати"/>
				</v-toolbar>
			</template>

			<template v-slot:default="props">
				<vue-flip :items="props.items">

					<template v-slot:default="{ item }">
						<test-card
							:test="item"/>
					</template>

				</vue-flip>

			</template>
		</v-data-iterator>

		<h2>Іспити</h2>

		<!-- TODO: Refactor exams -->
		<v-row>
			<v-col
				v-for="exam in exams"
				:key="exam.id"
				cols="12"
				sm="6"
				md="4"
				lg="3"
				xl="2">
				<v-card
					:to="{
						name: 'exam',
						params: {
							testid: exam.id,
						},
					}"
					elevation="24"
					height="10em">
					<v-card-title>
						{{ exam.name }}
					</v-card-title>
				</v-card>
			</v-col>
		</v-row>

		<!-- <swipeable-bottom-sheet ref="swipeableBottomSheet" defaultState="half">
			<h1>Lorem Ipsum</h1>
			<h2>What is Lorem Ipsum?</h2>
			<p>
			<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
			</p>
		</swipeable-bottom-sheet> -->

	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TestCard from '../components/TestCard'
import VueFlip from '../components/VueFlip'
// import SwipeableBottomSheet from '../components/SwipeableBottomSheet'

import Exam from '../models/Exam'

export default {
	props: {
		subjectid: Number,
	},
	components: {
		TestCard,
		VueFlip,
		// SwipeableBottomSheet,
	},
	data: () => ({
		search: '',
		filterBy: null,
		filterKeys: [
			{
				text: 'Усі',
				value: null,
			},
			{
				text: 'Пройдені',
				value: 1,
			},
			{
				text: 'Не пройдені',
				value: 2,
			},
		],
		sortBy: null,
		sortKeys: [
			{
				text: 'Імя',
				value: 'test_name',
			},
			{
				text: 'Результатом',
				value: 'result.result',
			},
		],
	}),
	computed: {
		...mapGetters('subjects', [
			'subject',
		]),
		...mapGetters('tests', [
			'tests',
		]),
		exams() {
			return Exam.all()
		},
		filteredTests() {
			if (this.filterBy) {
				return this.tests.filter( test => this.filterBy === 1 ? test.result : !test.result)
			}
			return this.tests
		},
	},
	methods: {
		...mapActions('subjects', [
			'loadSubject',
			'clearSubject',
		]),
		...mapActions('tests', [
			'loadTestsBySubject',
		]),
		async loadExams() {
			try {
				await Exam.fetchGetAllBySubject(this.subjectid)
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список іспитів',
					color: 'error',
				}, { root: true })
			}
		},
	},
	mounted() {
		this.loadSubject(this.subjectid)
		this.loadTestsBySubject(this.subjectid)
		this.loadExams()
	},
	beforeDestroy() {
		this.clearSubject()
	},
}
</script>