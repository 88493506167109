<template>
	<v-card
		class="accent-2 box"
		:class="[
			moved ? 'moved' : ''
		]"
		:color="color"
		:ripple="false"
		elevation="24"
		:to="{
			name: 'test',
			params: {
				testid: test.id
			}
		}">
		<v-card-title
			v-if="test.result"
			class="font-weight-black font-italic black--text">
			{{ test.result.result }}%
		</v-card-title>
		<v-card-text class="black--text">{{ test.name }}</v-card-text>
	</v-card>
</template>

<script>
export default {
	props: {
		test: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		moved: false,
		animation: false,
	}),
	computed: {
		color() {
			return this.test.result ? 'amber' : 'success'
		},
	},
	methods: {
		extend() {
			return this.moved = !this.moved
		},
	},
}
</script>