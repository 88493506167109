var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v(_vm._s(_vm.subject.name))]),_c('small',[_vm._v("Тести")]),_c('v-data-iterator',{attrs:{"items":_vm.filteredTests,"search":_vm.search,"sort-by":_vm.sortBy},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-toolbar',{staticClass:"mb-1",attrs:{"elevation":"0","color":"transparent"}},[_c('v-text-field',{attrs:{"clearable":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":"Пошук"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-select',{attrs:{"hide-details":"","items":_vm.filterKeys,"label":"Фільтрувати"},model:{value:(_vm.filterBy),callback:function ($$v) {_vm.filterBy=$$v},expression:"filterBy"}}),_c('v-select',{attrs:{"hide-details":"","items":_vm.sortKeys,"label":"Сортувати"},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}})],1)]},proxy:true},{key:"default",fn:function(props){return [_c('vue-flip',{attrs:{"items":props.items},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('test-card',{attrs:{"test":item}})]}}],null,true)})]}}])}),_c('h2',[_vm._v("Іспити")]),_c('v-row',_vm._l((_vm.exams),function(exam){return _c('v-col',{key:exam.id,attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-card',{attrs:{"to":{
					name: 'exam',
					params: {
						testid: exam.id,
					},
				},"elevation":"24","height":"10em"}},[_c('v-card-title',[_vm._v(" "+_vm._s(exam.name)+" ")])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }